import { ThemeProvider } from '@emotion/react';
import { CssBaseline, createTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { JSX } from 'react/jsx-runtime';
import Detail from './components/detail/detail';
import Home from './components/home/home';
import Login from './components/login/login';
import { ProtectedRoute } from './components/protected-route';

/**
 * App.tsx where we set the light or dark theme and attribute "path" routes to a component which is a JSX Element.
 * 
 * @returns JSX Element to integrate it in the index file
 */
function App(): JSX.Element {

  // Get the light or dark theme preference of the user and set it in react with useMemo
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // dark or light theme
          mode: prefersDarkMode ? 'dark' : 'light'
        }
      }),
    [prefersDarkMode]
  );


  // return the theme provider with Material theme CSS and react router dom routes with Home route and Detail route
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/*" index element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />
        <Route path="/detail/*" element={
          <ProtectedRoute>
            <Detail />
          </ProtectedRoute>
        } />
      </Routes>
    </ThemeProvider>
  );
}

export default App;