import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/primeflex/primeflex.min.css';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store';

/**
 * Main file where we provide the react redux store and the react router dom router.
 */
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// ENV variable for the backend address
const clientId = process.env.REACT_APP_G_CLIENT_ID;

root.render(
  <GoogleOAuthProvider clientId={clientId!}>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </GoogleOAuthProvider>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
