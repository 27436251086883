import { createSlice } from '@reduxjs/toolkit'
import Experience from '../model/experience.model'
import Learning from '../model/learning.model'
import SkillFolder from '../model/skillFolder.model'

interface SkillFolderState {
    loading: boolean,
    skillFolders: SkillFolder[]
}

const initialState: SkillFolderState = {
    loading: false,
    skillFolders: [],
}

const skillFoldersSlice = createSlice({
    name: 'skillFolders',
    initialState: initialState,
    reducers: (create) => ({
        deleteSkillFolder: create.reducer<number>((state, action) => {
            state.skillFolders = state.skillFolders.filter(sk => sk.skill_folder_id !== action.payload);
        }),

        addSkillFolder: create.preparedReducer(
            (text: SkillFolder) => {
                return { payload: text }
            },
            // action type is inferred from prepare callback
            (state, action) => {
                state.skillFolders.push(action.payload);
            }
        ),

        addExperience: create.preparedReducer(
            (text: Experience) => {
                return { payload: text }
            },
            (state, action) => {
                state.skillFolders.find((sf) => {
                    return sf.skill_folder_id === action.payload.skill_folder_id;
                })?.experiences.push(action.payload);
            }
        ),

        addLearning: create.preparedReducer(
            (text: Learning) => {
                return { payload: text }
            },
            (state, action) => {
                state.skillFolders.find((sf) => {
                    return sf.skill_folder_id === action.payload.skill_folder_id;
                })?.learnings.push(action.payload);
            }
        ),

        removeExperience: create.reducer<{ skillFolderid: number, experienceId: number }>((state, action) => {
            let skillFolder: SkillFolder = state.skillFolders.find((sf) => {
                return sf.skill_folder_id === action.payload.skillFolderid;
            })!;
            skillFolder.experiences = skillFolder.experiences.filter(exp => exp.experience_id !== action.payload.experienceId);
        }),

        removeLearning: create.reducer<{ skillFolderid: number, learningId: Number }>((state, action) => {
            let skillFolder: SkillFolder = state.skillFolders.find((sf) => {
                return sf.skill_folder_id === action.payload.skillFolderid;
            })!;
            skillFolder.learnings = skillFolder.learnings.filter(learning => learning.learning_id !== action.payload.learningId);
        }),

        updateSkillFolder: create.preparedReducer(
            (text: SkillFolder) => {
                return { payload: text }
            },
            // action type is inferred from prepare callback
            (state, action) => {
                // delete the item in the state
                state.skillFolders = state.skillFolders.filter(sk => sk.skill_folder_id !== action.payload.skill_folder_id);

                // add it again in the state
                state.skillFolders.push(action.payload);
            }
        ),

        updateExperience: create.preparedReducer(
            (text: Experience) => {
                return { payload: text }
            },
            // action type is inferred from prepare callback
            (state, action) => {
                // delete the item in the list
                let skillFolder: SkillFolder = state.skillFolders.find((sf) => {
                    return sf.skill_folder_id === action.payload.skill_folder_id;
                })!;
                skillFolder.experiences = skillFolder.experiences.filter(exp => exp.experience_id !== action.payload.experience_id);

                // add it again in the list
                skillFolder.experiences.push(action.payload);
            }
        ),

        updateLearning: create.preparedReducer(
            (text: Learning) => {
                return { payload: text }
            },
            // action type is inferred from prepare callback
            (state, action) => {
                // delete the item in the list
                let skillFolder: SkillFolder = state.skillFolders.find((sf) => {
                    return sf.skill_folder_id === action.payload.skill_folder_id;
                })!;
                skillFolder.learnings = skillFolder.learnings.filter(fo => fo.learning_id !== action.payload.learning_id);

                // add it again in the list
                skillFolder.learnings.push(action.payload);
            }
        ),


    }),
    selectors: {
        selectSkillFolders: (state) => state.skillFolders,
    }
})

export const { addSkillFolder, deleteSkillFolder, addExperience, addLearning, removeExperience, removeLearning, updateSkillFolder, updateExperience, updateLearning } = skillFoldersSlice.actions
export const { selectSkillFolders } = skillFoldersSlice.selectors
export default skillFoldersSlice