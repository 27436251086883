import { Adb, Edit } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, Container, IconButton, Modal, TextField, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import React, { useState } from "react";
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { JSX } from 'react/jsx-runtime';
import imgLizard from '../../images/lizard-iguana.jpg';
import Experience from '../../model/experience.model';
import Learning from '../../model/learning.model';
import SkillFolder from '../../model/skillFolder.model';
import { addExperience, addLearning, removeExperience, removeLearning, updateExperience, updateLearning, updateSkillFolder } from '../../reducer/skillFolder.reducer';
import { RootState } from '../../store';
// QuillEditor - Importing core components
import QuillEditor from "react-quill";
// QuillEditor - Importing styles
import "react-quill/dist/quill.snow.css";
import styles from "../styles.module.css";

/**
 * Detail Component of the application.
 * 
 * Here we set different fields :
 * - a title in top center
 * - an iguana image
 * - a first field with preamble, Languages, Availability date and experience years
 * - experiences and actions (edit/delete)
 * - skills and actions (edit/delete)
 * - learnings/certifications and actions (edit/delete)
 * 
 * To see details in Notion : https://www.notion.so/Affichage-du-d-tail-du-CV-68af2ab8f0094b3eac633af749d00456?pvs=4
 * 
 * @returns JSX Element for inclusion in "App"
 */
function Detail(): JSX.Element {

   // ENV variable for the backend address
   const apiUrl = process.env.REACT_APP_API_URL?.startsWith("http") ? process.env.REACT_APP_API_URL : "/" + process.env.REACT_APP_API_URL;

   // react hooks
   const dispatch = useDispatch();
   const navigate = useNavigate();

   // Get the actual skillFolder added before in the store with the consultant inside
   const [queryParameters] = useSearchParams();

   // react useSelector to select in the state the right skillFolder thanks to the query Parameter in the URL
   let actualSkillFolder: SkillFolder = useSelector((state: RootState) => state.skillFolder.skillFolders.find((sf) => {
      return sf.skill_folder_id === parseInt(queryParameters.get("skill_folder_id")?.valueOf()!)
   }), shallowEqual)!;



   /****************************************************
    *   ******* Experience *********
    * 
    ****************************************************
    */

   // Form methods for Adding/Edit Experience
   const {
      control: controlAddingEditExperience,
      register: registerAddingEditExperience,
      handleSubmit: handleSubmitAddingEditExperience,
      reset: resetAddingEditExperience,
      setValue: setValueAddingEditExperience,
      getValues: getValuesAddingEditExperience,
      formState: { isValid: isValidAddingEditExperience },
   } = useForm();

   /****************************************************
    *          Adding experience
    * 
    ****************************************************
    */

   // Modal Adding experience needs
   const [openAddingExperienceModal, setOpenAddingExperienceModal] = useState(false);
   const handleOpenAddingExperienceModal = () => setOpenAddingExperienceModal(true);
   const handleCloseAddingExperienceModal = () => setOpenAddingExperienceModal(false);

   /**
    * Post method to add an experience in the SkillFolder (thanks to the skill_folder_id) in the DB and dispatch after in the redux store.
    * 
    * @param data Adding Experience form values
    */
   async function createExperience(data: FieldValues) {
      // Thymeleaf dont like <br> tags...
      data['details'] = data['details'].replace(/<br>/g, " ");
      await fetch(apiUrl + `/cvblockform/experience?skillFolderId=${actualSkillFolder.skill_folder_id}`, {
         method: 'POST',
         body: JSON.stringify(data),
         headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: sessionStorage.getItem("tokenId")! }
      }).then((response) => {
         return response.json();
      }).then((responseData: Experience) => {
         dispatch(addExperience(responseData));
      })
      setOpenAddingExperienceModal(false);
      resetAddingEditExperience();
   }

   /**
    * Delete method to remove an experience in the SkillFolder (thanks to the skill_folder_id) in the DB and dispatch after in the redux store.
    * 
    * @param experienceId ID of the experience
    */
   async function deleteExperience(experienceId: number) {
      await fetch(apiUrl + `/cvblockform/experience?skillFolderId=${actualSkillFolder.skill_folder_id}&experienceId=${experienceId}`, {
         method: 'DELETE',
         headers: {
            Authorization: sessionStorage.getItem("tokenId")!
         }
      }).then((response) => {
         if (response.status === 204) {
            const data = {
               skillFolderid: actualSkillFolder.skill_folder_id,
               experienceId: experienceId
            };
            dispatch(removeExperience(data));
         }
      });
   }

   /****************************************************
    *          Edit experience
    * 
    ****************************************************
    */

   // Modal Edit experience needs
   const [openEditExperienceModal, setOpenEditExperienceModal] = useState(false);
   const handleOpenEditExperienceModal = () => setOpenEditExperienceModal(true);
   const handleCloseEditExperienceModal = () => setOpenEditExperienceModal(false);

   /**
    * Put method to edit an experience in the SkillFolder (thanks to the skill_folder_id) in the DB and dispatch after in the redux store.
    * 
    * @param data Edit Experience form values 
    */
   async function editExperience(data: FieldValues) {
      // Thymeleaf dont like <br> tags...
      data['details'] = data['details'].replace(/<br>/g, " ");
      await fetch(apiUrl + `/cvblockform/experience?skillFolderId=${actualSkillFolder.skill_folder_id}`, {
         method: 'PUT',
         body: JSON.stringify(data),
         headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: sessionStorage.getItem("tokenId")! }
      }).then((response) => {
         return response.json();
      }).then((responseData: Experience) => {
         dispatch(updateExperience(responseData));
      })
      setOpenEditExperienceModal(false);
      resetAddingEditExperience();
   }


   /****************************************************
    *   ******* Skill *********
    * 
    ****************************************************
    */

   // Form methods for Adding/Edit Skill
   const {
      control: controlAddingEditSkill,
      handleSubmit: handleSubmitAddingEditSkill,
      reset: resetAddingEditSkill,
      setValue: setValueAddingEditSkill,
      getValues: getValuesAddingEditSkill,
   } = useForm();

   // Modal Adding skill needs
   const [openAddingEditSkillModal, setOpenAddingEditSkillModal] = React.useState(false);
   const handleOpenAddingEditSkillModal = () => setOpenAddingEditSkillModal(true);
   const handleCloseAddingEditSkillModal = () => setOpenAddingEditSkillModal(false);

   /**
    * Put method to add/edit skills in the SkillFolder (thanks to the skill_folder_id) in the DB and dispatch after in the redux store.
    * 
    * @param data Adding/Edit skills form value
    */
   async function updateSkills(data: FieldValues) {
      // Thymeleaf dont like <br> tags...
      data['skills'] = data['skills'].replace(/<br>/g, " ");
      actualSkillFolder = { ...actualSkillFolder, skills: data['skills'] }
      await fetch(apiUrl + `/cvblockform/skillfolder?skillFolderId=${actualSkillFolder!.skill_folder_id}`, {
         method: 'PUT',
         body: JSON.stringify(actualSkillFolder),
         headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: sessionStorage.getItem("tokenId")! }
      }).then((response) => {
         return response.json();
      }).then((responseData: SkillFolder) => {
         dispatch(updateSkillFolder(responseData));
      })
      setOpenAddingEditSkillModal(false);
      resetAddingEditSkill();
   }


   /****************************************************
    *   ******* Learning *********
    * 
    ****************************************************
    */

   // Form methods for Adding/Edit Learning
   const {
      control: controlAddingEditLearning,
      register: registerAddingEditLearning,
      handleSubmit: handleSubmitAddingEditLearning,
      reset: resetAddingEditLearning,
      setValue: setValueAddingEditLearning,
      getValues: getValuesAddingEditLearning,
   } = useForm();

   /****************************************************
    *          Adding Learning
    * 
    ****************************************************
    */

   // Modal Adding learning needs
   const [openAddingLearningModal, setOpenAddingLearningModal] = useState(false);
   const handleOpenAddingLearningModal = () => setOpenAddingLearningModal(true);
   const handleCloseAddingLearningModal = () => setOpenAddingLearningModal(false);

   /**
    * Post method to add a learning in the SkillFolder (thanks to the skill_folder_id) in the DB and dispatch after in the redux store.
    * 
    * @param data Adding Learning form values
    */
   async function createLearning(data: FieldValues) {
      await fetch(apiUrl + `/cvblockform/learning?skillFolderId=${actualSkillFolder.skill_folder_id}`, {
         method: 'POST',
         body: JSON.stringify(data),
         headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: sessionStorage.getItem("tokenId")! }
      }).then((response) => {
         return response.json();
      }).then((responseData: Learning) => {
         dispatch(addLearning(responseData));
      })
      setOpenAddingLearningModal(false);
      resetAddingEditLearning();
   }

   /**
    * Delete method to remove a learning in the SkillFolder (thanks to the skill_folder_id) in the DB and dispatch after in the redux store.
    * 
    * @param learningId ID of the learning
    */
   async function deleteLearning(learningId: number) {
      await fetch(apiUrl + `/cvblockform/learning?skillFolderId=${actualSkillFolder.skill_folder_id}&learningId=${learningId}`, {
         method: 'DELETE',
         headers: {
            Authorization: sessionStorage.getItem("tokenId")!
         }
      }).then((response) => {
         if (response.status === 204) {
            const data = {
               skillFolderid: actualSkillFolder.skill_folder_id,
               learningId: learningId
            };
            dispatch(removeLearning(data));
         }
      });
   }

   /****************************************************
    *          Edit learning
    * 
    ****************************************************
    */

   // Modal Edit learning needs
   const [openEditLearningModal, setOpenEditLearningModal] = useState(false);
   const handleOpenEditLearningModal = () => setOpenEditLearningModal(true);
   const handleCloseEditLearningModal = () => setOpenEditLearningModal(false);

   /**
    * Put method to edit a Learning in the SkillFolder (thanks to the skill_folder_id) in the DB and dispatch after in the redux store.
    * 
    * @param data Edit Learning form values 
    */
   async function editLearning(data: FieldValues) {
      await fetch(apiUrl + `/cvblockform/learning?skillFolderId=${actualSkillFolder.skill_folder_id}`, {
         method: 'PUT',
         body: JSON.stringify(data),
         headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: sessionStorage.getItem("tokenId")! }
      }).then((response) => {
         return response.json();
      }).then((responseData: Learning) => {
         dispatch(updateLearning(responseData));
      })
      setOpenEditLearningModal(false);
      resetAddingEditLearning();
   }

   /**
    * Render the full page if a skillFolder is found ...
    */
   if (actualSkillFolder) {
      return (
         <div>
            {/* Title */}
            <Container maxWidth="lg">
               <div className="text-center">
                  <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/home`} ><h1><Adb />Mise en page des CVs - CvBlockForm</h1></Link>
               </div>
            </Container>

            {/* Body with the details of the CV */}
            <Container maxWidth="lg" className='pt-1'>
               <Typography sx={{ mb: 2 }} variant="h6" component="div">
                  Détails du CV
               </Typography>

               <Card className='w-full'>
                  <CardMedia
                     sx={{ height: '10rem' }}
                     image={imgLizard}
                     title="green iguana"
                  />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div" className='flex justify-content-between'>
                        <div>{actualSkillFolder.last_name} {actualSkillFolder.first_name}</div>
                        <div>{actualSkillFolder.actual_function}</div>
                     </Typography>
                     <QuillEditor
                        theme="bubble"
                        value={"" + actualSkillFolder.preamble}
                        id="preamble"
                        readOnly={true}
                     />
                     <Typography className="ml-2" variant="body1" color="text.secondary">
                        Langues : {actualSkillFolder.languages.toString()}
                     </Typography>
                     <Typography className="ml-2" variant="body1" color="text.secondary">
                        Date de disponibilité : {new Date(actualSkillFolder.availability).toLocaleDateString("fr")}
                     </Typography>
                     <Typography className="ml-2" variant="body1" color="text.secondary">
                        Annees d'XP : {actualSkillFolder.experience_years.toString()}
                     </Typography>

                     {/* Accordion for Experiences */}
                     <Accordion>
                        <AccordionSummary
                           expandIcon={<ArrowDropDownIcon />}
                           aria-controls="panel1-content"
                           id="panel1-header"
                        >
                           <Typography>Expériences</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography component={'span'}>
                              {actualSkillFolder.experiences.map((exp, index) =>
                                 <Card variant="outlined" className='m-2' key={index.toString()}>
                                    <CardHeader
                                       avatar={<Avatar>
                                          XP
                                       </Avatar>}
                                       action={<div>
                                          <IconButton aria-label="settings" onClick={() => {
                                             resetAddingEditExperience();
                                             setValueAddingEditExperience('experience_id', exp.experience_id);
                                             setValueAddingEditExperience('client', exp.client);
                                             setValueAddingEditExperience('work_function', exp.work_function);
                                             setValueAddingEditExperience('begin_date', exp.begin_date);
                                             setValueAddingEditExperience('ending_date', exp.ending_date);
                                             setValueAddingEditExperience('details', exp.details);
                                             handleOpenEditExperienceModal();
                                          }}>
                                             <Edit color='primary' />
                                          </IconButton>
                                          <IconButton aria-label="settings" onClick={() => deleteExperience(exp.experience_id)}>
                                             <DeleteIcon color='error' />
                                          </IconButton>
                                       </div>}
                                       title="Détails de l'expérience"
                                       subheader={<div className='flex m-2'>
                                          <div className='flex-column col-6'>
                                             <Typography><b>Client :</b> {exp.client} </Typography>
                                             <Typography><b>Date de début :</b> {new Date(exp.begin_date).toLocaleDateString("fr")} </Typography>
                                          </div>
                                          <div className='flex-column col-6'>
                                             <Typography><b>Poste :</b> {exp.work_function} </Typography>
                                             <Typography><b>Date de fin :</b> {new Date(exp.ending_date).toLocaleDateString("fr")} </Typography>
                                          </div>
                                       </div>} />
                                    <Divider />
                                    <div>
                                       <QuillEditor
                                          theme="bubble"
                                          value={exp.details}
                                          id="details"
                                          readOnly={true}
                                       />
                                    </div>
                                 </Card>
                              )}
                           </Typography>
                           <Button className='m-3' variant='outlined' onClick={() => {
                              resetAddingEditExperience();
                              handleOpenAddingExperienceModal();
                           }}>Ajouter une expérience</Button>
                        </AccordionDetails>
                     </Accordion>

                     {/* Accordion for Skills */}
                     <Accordion>
                        <AccordionSummary
                           expandIcon={<ArrowDropDownIcon />}
                           aria-controls="panel2-content"
                           id="panel2-header"
                        >
                           <Typography>Compétences</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography component={'span'}>
                              <div>
                                 <QuillEditor
                                    theme="bubble"
                                    value={"" + actualSkillFolder.skills}
                                    id="skillsview"
                                    readOnly={true}
                                 />
                              </div>
                           </Typography>
                           <Button className='m-3' variant='outlined' onClick={() => {
                              resetAddingEditSkill();
                              setValueAddingEditSkill('skills', actualSkillFolder.skills);
                              handleOpenAddingEditSkillModal();
                           }}>Modifier les compétences</Button>
                        </AccordionDetails>
                     </Accordion>

                     {/* Accordion for Learnings */}
                     <Accordion>
                        <AccordionSummary
                           expandIcon={<ArrowDropDownIcon />}
                           aria-controls="panel2-content"
                           id="panel2-header"
                        >
                           <Typography>Formations/Certifications</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography component={'span'}>
                              {actualSkillFolder.learnings.map((cert, index) =>
                                 <Card variant="outlined" className='m-2' key={index.toString()}>
                                    <CardHeader
                                       avatar={<Avatar>
                                          FO
                                       </Avatar>}
                                       action={<div>
                                          <IconButton aria-label="settings" onClick={() => {
                                             resetAddingEditLearning();
                                             setValueAddingEditLearning('learning_id', cert.learning_id);
                                             setValueAddingEditLearning('name', cert.name);
                                             setValueAddingEditLearning('institution', cert.institution);
                                             setValueAddingEditLearning('begin_date', cert.begin_date);
                                             setValueAddingEditLearning('ending_date', cert.ending_date);
                                             handleOpenEditLearningModal();
                                          }}>
                                             <Edit color='primary' />
                                          </IconButton>
                                          <IconButton aria-label="settings" onClick={() => deleteLearning(cert.learning_id)}>
                                             <DeleteIcon color='error' />
                                          </IconButton>
                                       </div>}
                                       title="Détails de la formation/certification" />
                                    <div className='flex flex-column m-2'>
                                       <Typography><b>Nom :</b> {cert.name} </Typography>
                                       <Typography><b>Etablissement :</b> {cert.institution} </Typography>
                                       <Typography><b>Date de début :</b> {new Date(cert.begin_date).toLocaleDateString("fr").substring(3).toString()} </Typography>
                                       <Typography><b>Date de fin :</b> {new Date(cert.ending_date).toLocaleDateString("fr").substring(3).toString()} </Typography>
                                    </div>
                                 </Card>
                              )}
                           </Typography>
                           <Button className='m-3' variant='outlined' onClick={() => {
                              resetAddingEditLearning();
                              handleOpenAddingLearningModal();
                           }}>Ajouter une formation</Button>
                        </AccordionDetails>
                     </Accordion>

                  </CardContent>
               </Card>

            </Container>


            {/* Modal for Adding/Edit experience */}
            <Modal
               open={openAddingExperienceModal || openEditExperienceModal}
               onClose={openAddingExperienceModal ? handleCloseAddingExperienceModal : handleCloseEditExperienceModal}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
               className='overflow-y-scroll'
            >
               <Box
                  className='top-50 left-50 absolute border-solid border-2 border-black-alpha-90 shadow-8 p-4 w-10'
                  sx={{ transform: 'translate(-50%, -50%)', bgcolor: 'background.paper' }}
               >
                  {
                     openAddingExperienceModal ?
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                           Ajouter une expérience
                        </Typography>
                        : <Typography id="modal-modal-title" variant="h6" component="h2">
                           Modifier une expérience
                        </Typography>
                  }
                  <Typography id="modal-modal-description" component={'span'} sx={{ mt: 2 }}>
                     <form onSubmit={handleSubmitAddingEditExperience((data) => openAddingExperienceModal ? createExperience(data) : editExperience(data))}>
                        <div className='flex flex-row mt-3'>
                           <div className='m-1 col-6'>
                              <TextField
                                 id="client"
                                 label="Client"
                                 className="w-11"
                                 {...registerAddingEditExperience('client', { required: true })}
                                 onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditExperience((data) => openAddingExperienceModal ? createExperience(data) : editExperience(data)) }}
                              />
                           </div>
                           <div className='m-1 col-6'>
                              <TextField
                                 id="work_function"
                                 label="Poste"
                                 className="w-11"
                                 {...registerAddingEditExperience('work_function', { required: true })}
                                 onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditExperience((data) => openAddingExperienceModal ? createExperience(data) : editExperience(data)) }}
                              />
                           </div>
                        </div>
                        <div className='flex flex-row'>
                           <div className='m-1 col-6'>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                 <DemoContainer components={['DatePicker']} >
                                    <Controller
                                       control={controlAddingEditExperience}
                                       name='begin_date'
                                       rules={{ required: true }}
                                       render={({ field }) => (
                                          <DatePicker
                                             label="Date de début"
                                             name='begin_date'
                                             defaultValue={openEditExperienceModal ? dayjs(getValuesAddingEditExperience("begin_date")) : undefined}
                                             format='DD/MM/YYYY'
                                             onChange={(date) => field.onChange(date)}
                                             className="w-11"
                                          />
                                       )}
                                    />
                                 </DemoContainer>
                              </LocalizationProvider>
                           </div>
                           <div className='m-1 col-6'>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                 <DemoContainer components={['DatePicker']} >
                                    <Controller
                                       control={controlAddingEditExperience}
                                       name='ending_date'
                                       rules={{ required: true }}
                                       render={({ field }) => (
                                          <DatePicker
                                             label="Date de fin"
                                             name='ending_date'
                                             defaultValue={openEditExperienceModal ? dayjs(getValuesAddingEditExperience("ending_date")) : undefined}
                                             format='DD/MM/YYYY'
                                             onChange={(date) => field.onChange(date)}
                                             className="w-11"
                                          />
                                       )}
                                    />
                                 </DemoContainer>
                              </LocalizationProvider>
                           </div>
                        </div>
                        <div className='col-12'>
                           <label className={styles.label}>Details</label>
                           <Controller
                              control={controlAddingEditExperience}
                              name='details'
                              rules={{ required: true }}
                              render={({ field }) => (
                                 <QuillEditor
                                    className={styles.editor}
                                    theme="snow"
                                    onChange={(text) => field.onChange(text)}
                                    defaultValue={openEditExperienceModal ? getValuesAddingEditExperience("details") : undefined}
                                    id="expdetails"
                                    modules={{
                                       toolbar: [
                                          [], // custom dropdown
                                          ["bold", "italic", "underline", "strike"],
                                          [{ "color": [] }, { "background": [] }],
                                          [{ "header": 1 }, { "header": 2 }, "blockquote", "code-block"],
                                          [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
                                          [{ "direction": "rtl" }, { "align": [] }],
                                          ["link"],
                                          ["clean"]
                                       ]
                                    }}
                                 />
                              )}
                           />
                        </div>
                        <div className='flex mt-4'>
                           {
                              openAddingExperienceModal ?
                                 <Button disabled={!isValidAddingEditExperience} variant='contained' className='mr-2' type='submit'>Ajouter</Button>
                                 : <Button disabled={!isValidAddingEditExperience} variant='contained' className='mr-2' type='submit'>Modifier</Button>
                           }
                           {!isValidAddingEditExperience && <b>Tout les champs sont mandatory.</b>}
                        </div>
                     </form>
                  </Typography>
               </Box>
            </Modal>

            {/* Modal for Adding/Edit skill */}
            <Modal
               open={openAddingEditSkillModal}
               onClose={handleCloseAddingEditSkillModal}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
               className='overflow-y-scroll'
            >
               <Box
                  className='top-50 left-50 absolute border-solid border-2 border-black-alpha-90 shadow-8 p-4 w-10'
                  sx={{ transform: 'translate(-50%, -50%)', bgcolor: 'background.paper' }}
               >
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                     Modifier les compétences
                  </Typography>
                  <Typography id="modal-modal-description" component={'span'}>
                     <form onSubmit={handleSubmitAddingEditSkill((data) => updateSkills(data))}>
                        <div className='col-12' style={{ marginTop: "2em", minHeight: "20vw" }}>
                           <Controller
                              control={controlAddingEditSkill}
                              name='skills'
                              rules={{ required: true }}
                              render={({ field }) => (
                                 <QuillEditor
                                    className={styles.editor}
                                    theme="snow"
                                    onChange={(text) => field.onChange(text)}
                                    defaultValue={getValuesAddingEditSkill("skills")}
                                    id="skills"
                                    modules={{
                                       toolbar: [
                                          [], // custom dropdown
                                          ["bold", "italic", "underline", "strike"],
                                          [{ "color": [] }, { "background": [] }],
                                          [{ "header": 1 }, { "header": 2 }, "blockquote", "code-block"],
                                          [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
                                          [{ "direction": "rtl" }, { "align": [] }],
                                          ["link"],
                                          ["clean"]
                                       ]
                                    }}
                                 />
                              )}
                           />
                        </div>
                        <div className='text-right'>
                           <Button variant='contained' className='mr-2' type='submit'>Modifier</Button>
                        </div>
                     </form>
                  </Typography>
               </Box>
            </Modal>

            {/* Modal for Adding/Edit learning */}
            <Modal
               open={openAddingLearningModal || openEditLearningModal}
               onClose={openAddingLearningModal ? handleCloseAddingLearningModal : handleCloseEditLearningModal}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
            >
               <Box sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
               }}>
                  {
                     openAddingLearningModal ?
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                           Ajouter une formation
                        </Typography>
                        : <Typography id="modal-modal-title" variant="h6" component="h2">
                           Modifier une formation
                        </Typography>
                  }
                  <Typography id="modal-modal-description" component={'span'} sx={{ mt: 2 }}>
                     <form onSubmit={handleSubmitAddingEditLearning((data) => openAddingLearningModal ? createLearning(data) : editLearning(data))}>
                        <div className='flex-column'>
                           <TextField
                              id="name"
                              label="Nom"
                              className="mt-3 mb-3 w-full"
                              {...registerAddingEditLearning('name')}
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditLearning((data) => openAddingLearningModal ? createLearning(data) : editLearning(data)) }}
                           />
                           <TextField
                              id="institution"
                              label="Etablissement"
                              {...registerAddingEditLearning('institution')}
                              className="mb-3 w-full"
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditLearning((data) => openAddingLearningModal ? createLearning(data) : editLearning(data)) }}
                           />
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']} >
                                 <Controller
                                    control={controlAddingEditLearning}
                                    name='begin_date'
                                    render={({ field }) => (
                                       <DatePicker
                                          label="Date de début"
                                          name='begin_date'
                                          defaultValue={openEditLearningModal ? dayjs(getValuesAddingEditLearning("begin_date")) : undefined}
                                          format='MM/YYYY'
                                          views={['month', 'year']}
                                          onChange={(date) => field.onChange(date)}
                                          className="mb-3 w-full"
                                       />
                                    )}
                                 />
                              </DemoContainer>
                           </LocalizationProvider>
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']} >
                                 <Controller
                                    control={controlAddingEditLearning}
                                    name='ending_date'
                                    render={({ field }) => (
                                       <DatePicker
                                          label="Date de fin"
                                          name='ending_date'
                                          defaultValue={openEditLearningModal ? dayjs(getValuesAddingEditLearning("ending_date")) : undefined}
                                          format='MM/YYYY'
                                          views={['month', 'year']}
                                          onChange={(date) => field.onChange(date)}
                                          className="mb-3 w-full"
                                       />
                                    )}
                                 />
                              </DemoContainer>
                           </LocalizationProvider>
                           <div>
                              {
                                 openAddingLearningModal ?
                                    <Button variant='contained' className='mr-2' type='submit'>Ajouter</Button>
                                    : <Button variant='contained' className='mr-2' type='submit'>Modifier</Button>
                              }
                           </div>
                        </div>
                     </form>
                  </Typography>
               </Box>
            </Modal>

         </div>
      )
   }
   else {
      // (transitory) ... or go to the home page
      navigate('/');
      return (
         <>Ne pas actualiser la page svp :)</>)
   }

}

export default Detail;
