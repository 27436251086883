import { Card, CardContent, CardHeader } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

function Login(): JSX.Element {

  let navigate = useNavigate();

  //set tokenId in sessionStorage
  function onSuccess(response: any) {
    sessionStorage.setItem("tokenId", response.credential);
    navigate('/home');
  };

  function onError() {
    console.log(new Date(new Date().setTime(new Date().getTime() + 60 * 60 * 1000)).toISOString() + " Error in sign in with Google");
  };
  
  return (
    <main>
      <div className='flex justify-content-center pt-8'>
        <Card className='flex flex-column justify-content-center align-items-center w-6 h-15rem  '>
          <CardHeader
            title="Login"
          />
          <CardContent>
            <GoogleLogin onSuccess={onSuccess} onError={onError} />
          </CardContent>
        </Card>
      </div>
    </main>
  );
}

export default Login;