import { Adb, Delete, Download, Edit } from '@mui/icons-material';
import { Box, Button, Container, IconButton, Modal, TextField, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { JSX } from 'react/jsx-runtime';
import SkillFolder from '../../model/skillFolder.model';
import { addSkillFolder, deleteSkillFolder, updateSkillFolder } from '../../reducer/skillFolder.reducer';
// QuillEditor - Importing core components
import QuillEditor from "react-quill";
// QuillEditor - Importing styles
import "react-quill/dist/quill.snow.css";
import styles from "../styles.module.css";

/**
 * Home Component of the application.
 * 
 * Here we set different fields :
 * - a title in top center
 * - a button to add a CV/SkillFolder
 * - a Material DataGrid to show base info about CV and actions (edit/delete)
 * 
 * To see details in Notion : https://www.notion.so/Liste-des-CVs-1c2c232e23a841f893da157635c592bb?pvs=4
 * 
 * @returns JSX Element for inclusion in "App"
 */
function Home(): JSX.Element {

   // ENV variable for the backend address
   const apiUrl = process.env.REACT_APP_API_URL;

   // react hooks
   const dispatch = useDispatch();
   const navigate = useNavigate();
   // state values in home
   const [skillFolders, setSkillFolders] = useState<SkillFolder[]>([]);
   const [actualSkillFolder, setActualSkillFolder] = useState<SkillFolder>();

   // Columns for Datagrid list of skillFolder / CV 
   const columns: GridColDef<SkillFolder>[] = [
      { field: 'first_name', headerName: 'Prénom', flex: 1 },
      { field: 'last_name', headerName: 'Nom', flex: 1 },
      { field: 'actual_function', headerName: 'Poste', flex: 1 },
      {
         field: 'availability', headerName: 'Disponibilité', flex: 1, renderCell: (params) => {
            return (
               new Date(params.value).toLocaleDateString("fr")
            )
         },
      },
      {
         field: 'modification_date', headerName: 'Date de mise à jour', flex: 1, renderCell: (params) => {
            return (
               new Date(params.value).toLocaleDateString("fr")
            )
         },
      },
      {
         field: 'actions', type: 'actions', flex: 1, renderCell: (params) => {
            return (
               <>
                  <IconButton aria-label="settings" onClick={() => downloadPdfSkillFolder(parseInt(params.id.toString()))}>
                     <Download color='secondary' />
                  </IconButton>
                  <IconButton aria-label="settings" onClick={() => {
                     resetAddingEditSkillFolder();
                     const skillFolder = skillFolders.find((sf) => sf.skill_folder_id === params.id)!;
                     setValueAddingEditSkillFolder('preamble', skillFolder.preamble);
                     setValueAddingEditSkillFolder('first_name', skillFolder.first_name);
                     setValueAddingEditSkillFolder('last_name', skillFolder.last_name);
                     setValueAddingEditSkillFolder('actual_function', skillFolder.actual_function);
                     setValueAddingEditSkillFolder('experience_years', skillFolder.experience_years);
                     setValueAddingEditSkillFolder('email', skillFolder.email);
                     setValueAddingEditSkillFolder('availability', skillFolder.availability);
                     setValueAddingEditSkillFolder('tjm', skillFolder.tjm);
                     setValueAddingEditSkillFolder('mobility', skillFolder.mobility);
                     setValueAddingEditSkillFolder('languages', skillFolder.languages);
                     setActualSkillFolder(skillFolder);
                     handleOpenEditSkillFolderModal();
                  }}>
                     <Edit color='primary' />
                  </IconButton>
                  <IconButton aria-label="settings" onClick={() => removeSkillFolder(parseInt(params.id.toString()))}>
                     <Delete color='error' />
                  </IconButton>
               </>
            );
         },
      }
   ];

   // Form methods for Adding/Edit SkillFolder
   const {
      control: controlAddingEditSkillFolder,
      register: registerAddingEditSkillFolder,
      handleSubmit: handleSubmitAddingEditSkillFolder,
      reset: resetAddingEditSkillFolder,
      setValue: setValueAddingEditSkillFolder,
      getValues: getValuesAddingEditSkillFolder,
      formState: { isValid: isValidAddingEditSkillFolder },
   } = useForm();


   /****************************************************
    *          Adding SkillFolder
    * 
    ****************************************************
    */

   // Modal Adding SkillFolder needs
   const [openAddingSkillFolderModal, setOpenAddingSkillFolderModal] = useState(false);
   const handleOpenAddingSkillFolderModal = () => setOpenAddingSkillFolderModal(true);
   const handleCloseAddingSkillFolderModal = () => setOpenAddingSkillFolderModal(false);

   /**
    * Post method to add a SkillFolder in the DB and dispatch after in the redux store.
    * 
    * @param data Adding SkillFolder form values 
    */
   async function createSkillFolder(data: FieldValues) {
      data['languages'] = data['languages'].split(', ');
      // Thymeleaf dont like <br> tags...
      data['preamble'] = data['preamble'].replace(/<br>/g, " ");
      await fetch(apiUrl + `/cvblockform/skillfolder`, {
         method: 'POST',
         body: JSON.stringify(data),
         headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: sessionStorage.getItem("tokenId")! }
      }).then((response) => {
         return response.json();
      }).then((responseData: SkillFolder) => {
         dispatch(addSkillFolder(responseData));
         setSkillFolders([...skillFolders, responseData]);
      })
      setOpenAddingSkillFolderModal(false);
      resetAddingEditSkillFolder();
   }

   /**
    * Delete method to remove a SkillFolder in the DB and dispatch after in the redux store.
    * 
    * @param skillFolderId ID of the skillFolder
    */
   async function removeSkillFolder(skillFolderId: number) {
      await fetch(apiUrl + `/cvblockform/skillfolder?skillFolderId=${skillFolderId}`, {
         method: 'DELETE',
         headers: {
            Authorization: sessionStorage.getItem("tokenId")!
         }
      }).then((response) => {
         if (response.status === 204) {
            dispatch(deleteSkillFolder(skillFolderId));
            const updatedSkillFolders = skillFolders.filter(sk => sk.skill_folder_id !== skillFolderId);
            setSkillFolders(updatedSkillFolders);
         }
      });
   }

   /****************************************************
    *          Edit SkillFolder
    * 
    ****************************************************
    */

   // Modal Edit SkillFolder needs
   const [openEditSkillFolderModal, setOpenEditSkillFolderModal] = useState(false);
   const handleOpenEditSkillFolderModal = () => setOpenEditSkillFolderModal(true);
   const handleCloseEditSkillFolderModal = () => setOpenEditSkillFolderModal(false);

   /**
    * Put method to edit a SkillFolder in the DB and dispatch after in the redux store.
    * 
    * @param data Edit SkillFolder form values 
    */
   async function editSkillFolder(data: FieldValues) {
      data['languages'] = data['languages'].split(', ');
      // Thymeleaf dont like <br> tags...
      data['preamble'] = data['preamble'].replace(/<br>/g, " ");
      data = {
         ...data,
         skills: actualSkillFolder?.skills,
         experiences: actualSkillFolder?.experiences,
         learnings: actualSkillFolder?.learnings
      };
      await fetch(apiUrl + `/cvblockform/skillfolder?skillFolderId=${actualSkillFolder!.skill_folder_id}`, {
         method: 'PUT',
         body: JSON.stringify(data),
         headers: { 'Content-Type': 'application/json; charset=UTF-8', Authorization: sessionStorage.getItem("tokenId")! }
      }).then((response) => {
         return response.json();
      }).then((responseData: SkillFolder) => {
         dispatch(updateSkillFolder(responseData));
         let updatedSkillFolders = skillFolders.filter(sk => sk.skill_folder_id !== actualSkillFolder!.skill_folder_id);
         updatedSkillFolders = [...updatedSkillFolders, responseData];
         setSkillFolders(updatedSkillFolders);
      })
      setOpenEditSkillFolderModal(false);
      setActualSkillFolder(undefined);
      resetAddingEditSkillFolder();
   }

   /**
    * useEffect used to get all the SkillFolders/CV from DB to print it when we arrive in the app.
    */
   useEffect(() => {
      // Add the skillFolder in state
      function addSkillFolderToStore(skillFolder: SkillFolder) {
         dispatch(addSkillFolder(skillFolder));
      };

      // Get from the backend the skillFolders in Database
      fetch(apiUrl + '/cvblockform/skillfolders', {
         method: 'GET',
         headers: {
            Authorization: sessionStorage.getItem("tokenId")!
         }
      }).then((response) => {
         const status = response.status;
         if (status === 200) {
            return response.json();
         } else {
            throw new Error("Not found");
         }
      }).then(data => {
         let decoded = data as SkillFolder[];
         if (decoded.length === 0) {
            console.log(new Date(new Date().setTime(new Date().getTime() + 60 * 60 * 1000)).toISOString() + " No CV");
         }
         decoded.forEach((sf) => {
            addSkillFolderToStore(sf);
         })
         setSkillFolders(decoded);
      }).catch((error: Error) => {
         if (error.message === "Not found") {
            console.log(new Date(new Date().setTime(new Date().getTime() + 60 * 60 * 1000)).toISOString() + " No CV");
            return;
         }
      });
   }, []);


   /**
    * Get method to download a SkillFolder as a PDF.
    * 
    * @param skillFolderId ID of the skillFolder
    */
   async function downloadPdfSkillFolder(skillFolderId: number) {
      await fetch(apiUrl + `/cvblockform/pdf?skillFolderId=${skillFolderId}`, {
         method: 'GET',
         headers: {
            Authorization: sessionStorage.getItem("tokenId")!
         }
      })
         .then((response) => response.blob())
         .then(blob => {
            // Create a temporary anchor element
            const a = document.createElement("a");
            a.style.display = "none";
            // Create a blob URL for the file
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            const skillFolder = skillFolders.find((sf) => {
               return sf.skill_folder_id === skillFolderId
            })
            // Set the filename
            a.download = "Meedz-Dossier-de-competences-" + skillFolder?.first_name.substring(0, 1).toUpperCase() + skillFolder?.last_name.substring(0, 2).toUpperCase() + ".pdf";
            // Append the anchor element to the body
            document.body.appendChild(a);
            // Simulate a click on the anchor element to trigger download
            a.click();
            // Remove the anchor element from the body
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
         });
   }


   /****************************************************
    *          JSX UI
    * 
    ****************************************************
   */
   return (
      <main>

         <div>

            {/* Title */}
            <Container maxWidth="lg">
               <div className="text-center">
                  <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/home`} ><h1><Adb />Mise en page des CVs - CvBlockForm<Adb /></h1></Link>
               </div>
            </Container>

            {/* Body with the list of consultants */}
            {
               skillFolders.length > 0 ?
                  <Container maxWidth="lg" className='pt-5'>
                     <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Liste des consultants
                     </Typography>
                     <Button className='m-3' variant='contained' onClick={() => {
                        resetAddingEditSkillFolder();
                        handleOpenAddingSkillFolderModal();
                     }}>Ajouter un CV</Button>
                     <DataGrid
                        rows={skillFolders}
                        columns={columns}
                        hideFooter={true}
                        getRowId={(row) => row.skill_folder_id}
                        onRowClick={(params) => navigate(`/detail/?skill_folder_id=${params.id}`)}
                     />
                  </Container>
                  : <Button className='m-3' variant='contained' onClick={() => {
                     resetAddingEditSkillFolder();
                     handleOpenAddingSkillFolderModal();
                  }}>Ajouter un CV</Button>
            }

         </div>

         {/* Modal for Adding CV */}
         <Modal
            open={openAddingSkillFolderModal || openEditSkillFolderModal}
            onClose={openAddingSkillFolderModal ? handleCloseAddingSkillFolderModal : handleCloseEditSkillFolderModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='overflow-y-scroll'
         >
            <Box
               className='top-50 left-50 absolute border-solid border-2 border-black-alpha-90 shadow-8 p-4 w-9'
               sx={{ transform: 'translate(-50%, -50%)', bgcolor: 'background.paper' }}>
               {
                  openAddingSkillFolderModal ?
                     <Typography id="modal-modal-title" variant="h6" component="h2">
                        Ajouter un CV
                     </Typography>
                     : <Typography id="modal-modal-title" variant="h6" component="h2">
                        Modifier un CV
                     </Typography>
               }
               <Typography id="modal-modal-description" sx={{ mt: 2 }} component={'span'}>
                  <form onSubmit={handleSubmitAddingEditSkillFolder((data) => openAddingSkillFolderModal ? createSkillFolder(data) : editSkillFolder(data))}>
                     <div className='col-12'>
                        <label className={styles.label}>Préambule</label>
                        <Controller
                           control={controlAddingEditSkillFolder}
                           name='preamble'
                           rules={{ required: true }}
                           render={({ field }) => (
                              <QuillEditor
                                 className={styles.editor}
                                 theme="snow"
                                 onChange={(text) => field.onChange(text)}
                                 defaultValue={openEditSkillFolderModal ? getValuesAddingEditSkillFolder("preamble") : undefined}
                                 id="sfpreamble"
                                 modules={{
                                    toolbar: [
                                       [], // custom dropdown
                                       ["bold", "italic", "underline", "strike"],
                                       [{ "color": [] }, { "background": [] }],
                                       [{ "header": 1 }, { "header": 2 }, "blockquote", "code-block"],
                                       [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
                                       [{ "direction": "rtl" }, { "align": [] }],
                                       ["link"],
                                       ["clean"]
                                    ]
                                 }}
                              />
                           )}
                        />
                     </div>
                     <div className='flex'>
                        <div className='flex-column col-6 mr-3'>
                           <TextField
                              id="first_name"
                              label="Prénom"
                              className="mt-3 mb-3 w-full"
                              {...registerAddingEditSkillFolder('first_name', { required: true })}
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditSkillFolder((data) => createSkillFolder(data)) }}
                           />
                           <TextField
                              id="last_name"
                              label="Nom"
                              className="mb-3 w-full"
                              {...registerAddingEditSkillFolder('last_name', { required: true })}
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditSkillFolder((data) => createSkillFolder(data)) }}
                           />
                           <TextField
                              id="actual_function"
                              label="Poste"
                              className="mb-3 w-full"
                              {...registerAddingEditSkillFolder('actual_function', { required: true })}
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditSkillFolder((data) => createSkillFolder(data)) }}
                           />
                           <TextField
                              id="experience_years"
                              type='number'
                              label="Années d'XP"
                              className="mb-3 w-full"
                              {...registerAddingEditSkillFolder('experience_years', { required: true, valueAsNumber: true })}
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditSkillFolder((data) => createSkillFolder(data)) }}
                           />
                           <TextField
                              id="email"
                              label="Email"
                              className="mb-3 w-full"
                              {...registerAddingEditSkillFolder('email', { required: true })}
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditSkillFolder((data) => createSkillFolder(data)) }}
                           />
                        </div>
                        <div className='flex-column col-6 mr-3'>
                           <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']} >
                                 <Controller
                                    control={controlAddingEditSkillFolder}
                                    name='availability'
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                       <DatePicker
                                          label="Disponibilié"
                                          name='availability'
                                          defaultValue={openEditSkillFolderModal ? dayjs(getValuesAddingEditSkillFolder("availability")) : undefined}
                                          format='DD/MM/YYYY'
                                          onChange={(date) => field.onChange(date)}
                                          className="mb-3 w-full"
                                       />
                                    )}
                                 />
                              </DemoContainer>
                           </LocalizationProvider>
                           <TextField
                              id="tjm"
                              type='number'
                              label="Tjm"
                              className="mb-3 w-full"
                              {...registerAddingEditSkillFolder('tjm', { valueAsNumber: true })}
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditSkillFolder((data) => createSkillFolder(data)) }}
                           />
                           <TextField
                              id="mobility"
                              label="Mobilité"
                              {...registerAddingEditSkillFolder('mobility')}
                              className="w-full mb-2"
                              onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditSkillFolder((data) => createSkillFolder(data)) }}
                           />
                           <Tooltip title="Séparer les langues par des virgules suivi d'un espace ('LANGUE 1, LANGUE 2')">
                              <TextField
                                 id="languages"
                                 label="Langues"
                                 type='text'
                                 {...registerAddingEditSkillFolder('languages', { required: true })}
                                 className="w-full mb-2"
                                 onKeyDown={(ev) => { if (ev.key === 'Enter') handleSubmitAddingEditSkillFolder((data) => createSkillFolder(data)) }}
                              />
                           </Tooltip>
                           <div className='flex mt-4'>
                              {
                                 openAddingSkillFolderModal ?
                                    <Button disabled={!isValidAddingEditSkillFolder} variant='contained' className='mr-2' type='submit'>Ajouter</Button>
                                    : <Button disabled={!isValidAddingEditSkillFolder} variant='contained' className='mr-2' type='submit'>Modifier</Button>
                              }
                              {!isValidAddingEditSkillFolder && <b>Tout les champs sont mandatory.</b>}
                           </div>
                        </div>
                     </div>
                  </form>
               </Typography>
            </Box>
         </Modal>

      </main>
   );
}

export default Home;