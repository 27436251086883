import { configureStore } from '@reduxjs/toolkit'
import skillFoldersSlice from '../src/reducer/skillFolder.reducer'

export const store = configureStore({
  reducer: {
    skillFolder: skillFoldersSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;