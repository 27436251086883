import React from 'react';
import { Navigate } from 'react-router-dom';
import { JwtPayload, jwtDecode } from 'jwt-decode';

// ENV variable for the backend address
const clientId = process.env.REACT_APP_G_CLIENT_ID;

/**
   * Interface to add 'email' property in default JwtPayload interface
   */
interface ExtendedJwtPayload extends JwtPayload {
    email: string
}

/**
 * Get the token in the sessionStorage and return true if valid.
 * 
 * @returns true if token valid
 */
function getValidTokenId(): boolean {
    const tokenId = sessionStorage.getItem("tokenId");
    try {
        const decoded = jwtDecode(tokenId!) as ExtendedJwtPayload;
        if (clientId === decoded.aud && decoded.email.includes('@meedz.io')) {
            console.log(new Date(new Date().setTime(new Date().getTime() + 60 * 60 * 1000)).toISOString() + " Signed in")
            return true;
        } else {
            console.log(new Date(new Date().setTime(new Date().getTime() + 60 * 60 * 1000)).toISOString() + " Not signed in");
            return false;
        }
    } catch (error) {
        console.log(new Date(new Date().setTime(new Date().getTime() + 60 * 60 * 1000)).toISOString() + " Not signed in " + error);
        return false;
    }
};

interface ProtectedRouteProps {
    children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const isValidTokenId = getValidTokenId();
    return isValidTokenId ? <>{children}</> : <Navigate to="/" replace />;
};